@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}
/* assumes radio input also has w-5 and h-5 classes. */
input[type='radio'] {
  min-width: 1.25rem;
}

@layer base {
  @font-face {
    font-family: BCSans;
    src: url('./fonts/BCSans-Regular.woff') format('woff');
  }
  @font-face {
    font-family: BCSans-Bold;
    font-weight: 600;
    src: url('./fonts/BCSans-Bold.woff') format('woff');
  }
  b,
  strong,
  .font-bold {
    font-family: BCSans-Bold, sans-serif;
  }
  p + p,
  ul + p,
  p + ul {
    margin-top: 0.75rem;
  }
}

@media (min-width: 768px) {
  .bar-separator > *:not(:last-child):after {
    content: '|';
    margin: 0 0.5rem;
    color: #fff;
  }
}
